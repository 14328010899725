import React, { PureComponent } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Drawer, Button, List, ListItem, ListItemIcon, Collapse, ListItemText } from "@material-ui/core"
import { capitalize } from "lodash"
import MenuIcon from "@material-ui/icons/Menu"

import { ExpandLess, ExpandMore } from "@material-ui/icons"

const ListWrapper = styled.div`
  min-width: 250px
`

const NestedList = styled(List)`
  &&& {
    padding-left: 20px
  }
`

const StyledLink = styled(Link)`
  &&& {
    text-decoration: none;
    color: black;
  }
`

const SideDrawer = styled(Drawer)`
  &&& {
    z-index: 99999999999999;
  }
`

const MenuButton = styled(Button)`
  &&& {
    &:focus {
      outline: none;
    }
  }
`

export interface ListItem {
  key?: string,
  id: string,
  name: string,
  href: string,
  icon?: React.ReactElement<any> | undefined,
  shouldCollapse?: boolean,
  nestedItems?: ListItem[] | undefined
}


interface PropsToStateMap {
  [key: string]: any
}

interface PropsToState {
  items: ListItem[],
  map?: PropsToStateMap,
  value?: boolean
}

interface TemporaryDrawerProps {
  items: ListItem[],
  expandAllOnOpen?: boolean,
  contractAllOnExit?: boolean
}

export default class TemporaryDrawer extends PureComponent<TemporaryDrawerProps, any> {
  constructor(props: TemporaryDrawerProps) {
    super(props)

    this.toggleDrawer = this.toggleDrawer.bind(this)
    this.toggleListItem = this.toggleListItem.bind(this)
    
    this.state = {
      isOpen: false,
      ...this.mapNestedItemPropsToState({items: props.items})
    } 
  }

  mapNestedItemPropsToState = ({ items, map = {}, value = false } : PropsToState) : PropsToStateMap => {
    items.forEach((item: ListItem) => {
      if (item.hasOwnProperty("shouldCollapse")) {
        map[`is${capitalize(item.name.replace(/ /g, ""))}Expanded`] = value
      }

      if (item.nestedItems) {
        this.mapNestedItemPropsToState({items: item.nestedItems, map, value })
      }
    })

    return map
  }

  toggleDrawer = () : void => {
    if (this.props.contractAllOnExit && this.state.isOpen) {
      this.setState({
        isOpen: !this.state.isOpen,
        ...this.mapNestedItemPropsToState({items: this.props.items})
      })
    } 
    else if (this.props.expandAllOnOpen && !this.state.isOpen) {
      this.setState({
        isOpen: !this.state.isOpen,
        ...this.mapNestedItemPropsToState({items: this.props.items, value: true})
      })
    } else {
      this.setState({ 
        isOpen: !this.state.isOpen 
      })
    }
  }

  toggleListItem = (name: string) : void => {
    this.setState((prevState: any) => ({
      [`is${capitalize(name)}Expanded`]: !prevState[`is${capitalize(name)}Expanded`]
    }))
  }

  generateList = () : JSX.Element => {
    const list = this.props.items.map(item => {
      if (item.nestedItems && item.nestedItems.length) {
        return this.createNestedList(item, item.shouldCollapse, 1)
      } else {
        return (
          <>
            <StyledLink
              key={item.id}
              id={item.id}
              to={item.href}>
              <ListItem button={true}>
                {item.icon ? (
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                ) : null}
                <span>{item.name}</span>
              </ListItem>
            </StyledLink>
          </>
        )
      }      
    })

    return (
      <ListWrapper>
        <List component="div" disablePadding={true}>
          {list}
        </List>
      </ListWrapper>
    )
  }

  createNestedList = (item: ListItem, shouldCollapse: boolean = false, depth: number) : JSX.Element => {
    if (item.nestedItems) {
      const safeName = item.name.replace(/ /g, "")

      return (
        <>
          <ListItem key={item.id} button={true} onClick={() => this.toggleListItem(safeName)} name={item.name} style={{ paddingLeft: depth * 20 - 5 }}>
            {
              item.icon ? (
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
              ) : null
            }
            <ListItemText primary={item.name}/>
              {
                shouldCollapse ? this.state[`is${capitalize(safeName)}Expanded`] ? <ExpandLess /> : <ExpandMore /> : null
              }
          </ListItem>
          {
            shouldCollapse ? (
              <Collapse in={this.state[`is${capitalize(safeName)}Expanded`]} timeout="auto" unmountOnExit={true}>
              {
                item.nestedItems.map((nestedItem: ListItem) => this.createNestedList(nestedItem, nestedItem.shouldCollapse, depth+1))
              }
              </Collapse>
            ) : item.nestedItems.map((nestedItem: ListItem) => this.createNestedList(nestedItem, nestedItem.shouldCollapse, depth+1))
          }
        </>
      )
    } else {
      return (
        <List key={item.id} component="div" disablePadding={true} style={{ paddingLeft: depth * 10 }}>
          <StyledLink 
            id={item.id}
            to={item.href}
            onClick={this.toggleDrawer}>
            <ListItem button={true}>
              {
                item.icon ? (
                  <ListItemIcon>
                    {item.icon}
                  </ListItemIcon>
                ) : null
              }
              <span>{item.name}</span>
            </ListItem>
          </StyledLink>
        </List>
      )
    }
  }

  render() {
    const sideList = this.generateList()

    return (
      <div>
        <MenuButton onClick={this.toggleDrawer}>
          <MenuIcon style={{ color: "white" }}/>
        </MenuButton>
        <SideDrawer
          anchor="right" 
          open={this.state.isOpen} 
          onClose={this.toggleDrawer}>
          <div
            tabIndex={0}
            role="button"
            onKeyDown={this.toggleDrawer}>
            {sideList}
          </div>
        </SideDrawer>
      </div>
    )
  }
}