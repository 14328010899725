import Typography from "typography"
import CodePlugin from 'typography-plugin-code'
import fairyGatesTheme from "typography-theme-fairy-gates"

import SiteTheme from "../theme"

fairyGatesTheme.plugins = [
  new CodePlugin()
]

fairyGatesTheme.bodyFontFamily = [ 'Roboto', 'Helvetica', 'serif' ]
fairyGatesTheme.googleFonts = [
  {
    name: 'Roboto',
    styles: [
      '300',
      '400',
      '500',
      '700'
    ]
  }
]

fairyGatesTheme.overrideThemeStyles = ({ rhythm, scale }, options) => {
  return {
    "h1, h2, h3": {
      ...scale(1 / 6),
      fontWeight: `normal`,
      color: `#999`,
      lineHeight: `1.2`,
    },
    "code, kbd, pre, samp": {
      fontFamily: `Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`,
    },
    code: {
      fontSize: `90%`,
    },
    "h1 code, h2 code": {
      color: `#112`,
      fontWeight: `bold`,
    },
    a: {
      color: `inherit`,
      textDecoration: 'none',
      textShadow: 'none',
      backgroundImage: 'none'
    },
    "a:hover": {
      color: SiteTheme.palette.primary.light +' !important',
      textDecoration: `none !important`,
    },
    img: {
      margin: `0`,
    }
  }
}

const typography = new Typography(fairyGatesTheme)

// Hot reload typography in development.
if (process.env.NODE_ENV !== 'production') {
  typography.injectStyles()
}

// Back out the below once Typography is upgraded for es6
export default typography

export const rhythm = typography.rhythm
export const scale = typography.scale
export const options = typography.options