export const Business = Object.freeze({
    name: "Strange Land Recording Studios",
    address: "201 NW Victoria Dr",
    city: "Lees Summit",
    state: "MO",
    zip: "64086",
    phoneNumber: "(816) 434-7022",
    email: "info@strangelandrecordingstudios.com",
    url: "https://www.strangelandrecordingstudios.com",
    facebook: "https://www.facebook.com/Strange-Land-Recording-Studios-288718525074358/",
    twitter: "https://twitter.com/slrecording",
    instagram: "https://www.instagram.com/slrecording/",
    youtube: "https://www.youtube.com/channel/UC-3f_DXe6DU2uXdup4-zhWg"
})