import React, { PureComponent } from 'react'
import styled from 'styled-components'
import GoogleMapReact from 'google-map-react'
import SVG from 'react-inlinesvg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons'

import theme from '../theme'
import Pin from '../images/strange-music-logo.svg'
import InquireServices from './inquireService'
import Modal from './modal'
import { Business } from '../utils/constants'

const Wrapper = styled.div`
    width: 100vw;    
`

const MapWrapper = styled.div`
    height: 350px;
    width: 100vw;
    box-shadow: 0px -2px 8px -4px black;
`

const SocialBar = styled.div`
    background: #555555;
    display: flex;
    width: 100vw;
    height: 80px;
    justify-content: space-around;
    align-items: center;
`

const InfoAreaWrapper = styled.div`
    background: #4E4E4E;
    padding: 40px 40px 20px 40px;
    width: 100vw;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const InfoArea = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 90vw;
    margin: 0 auto 40px auto;

    @media(max-width: 568px) {
        flex-direction: column;
    }
`
interface ContactAreaProps {
    margin: string
}

const ContactArea = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: ${(props: ContactAreaProps) => props.margin ? props.margin : 'initial'};

    @media(max-width: 568px) {
        margin: 0 0 20px 0;

        > div {
            margin-bottom: 20px;
        }
    }
`

const ContactAreaSection = styled.div`
    display: flex;

    > svg {
        margin-right: 20px;
    }
`

const ContactAreaLink = styled.a`
    color: white;
    font-weight: 300;

    :hover {
        color: #D4D4D4;
        text-decoration: none;
    }
`

const InfoText = styled.p`
    font-size: 12px;
    margin: 0;
`

const SectionTitle = styled.p`
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 16px;
    margin-bottom: 15px;
    grid-area: 'header';
`

const HoursGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: repeat(7, auto);
    grid-template-areas: 
        'header header'
        'mon-d mon-t'
        'tue-d tue-t'
        'wed-d wed-t'
        'thu-d thu-t'
        'fri-d fri-t'
        'sat-d sat-t'
        'sun-d sun-t';
    font-size: 12px;

    > p {
        &:first-child {
            margin: 0 0 10px 0;
        }

        margin: 0 0 5px 0;
        color: white;
    }

    @media(max-width: 445px) {
        width: 75%;
        margin: 40px auto 0 auto;
    }
`

const MondayDay = styled.p`
    grid-area: mon-d
`

const MondayTime = styled.p`
    grid-area: mon-t
`

const TuesdayDay = styled.p`
    grid-area: tue-d
`

const TuesdayTime = styled.p`
    grid-area: tue-t
`

const WednesdayDay = styled.p`
    grid-area: wed-d
`

const WednesdayTime = styled.p`
    grid-area: wed-t
`

const ThursdayDay = styled.p`
    grid-area: thu-d
`

const ThursdayTime = styled.p`
    grid-area: thu-t
`

const FridayDay = styled.p`
    grid-area: fri-d
`

const FridayTime = styled.p`
    grid-area: fri-t
`

const SaturdayDay = styled.p`
    grid-area: sat-d
`

const SaturdayTime = styled.p`
    grid-area: sat-t
`

const SundayDay = styled.p`
    grid-area: sun-d
`

const SundayTime = styled.p`
    grid-area: sun-t
`

const PrivacyPolicy = styled.a`
    color: white;
    width: fit-content;
    font-size: 10px;
    margin: 0 auto 10px auto;
    text-decoration: underline;

    &:hover {
        color: rgba(255,255,255,0.8);
    }
`

const Copyright = styled.p`
    color: white;
    font-size: 12px;
    text-align: center;
    margin: 0;
`

const PinSVG = styled(SVG)`
  & {    
    svg {
      width: 50px;
      height: 50px;
      fill: ${theme.palette.primary.main}
    }
  }
`

const MapPin: React.SFC<{lat: number, lng: number}> = (props) => (
    <PinSVG src={Pin} />
)

interface FooterState {
    shouldDisplayPrivacyPolicy: boolean
}

export default class Footer extends PureComponent<{}, FooterState> {
    constructor(props: {}) {
        super(props)

        this.handleModal = this.handleModal.bind(this)

        this.state = {
            shouldDisplayPrivacyPolicy: false
        }
    }

    handleModal = (event: React.MouseEvent) => {
        this.setState({
            shouldDisplayPrivacyPolicy: event.currentTarget.tagName === 'A' ? true : false
        })
    }

    render() {
        const expandedPrivacyPolicy = this.state.shouldDisplayPrivacyPolicy ? (
            <Modal handleClose={this.handleModal} />
        ) : null

        return(
            <Wrapper>
                <InquireServices />
                <MapWrapper>
                    <GoogleMapReact
                        bootstrapURLKeys={{
                            key:process.env.GOOGLE_API_KEY
                        }}
                        defaultCenter={{
                            lat: 38.9366031,
                            lng: -94.3836404
                        }}
                        defaultZoom={18}>
                        <MapPin lat={38.9366031} lng={-94.3836404} />
                    </GoogleMapReact>
                </MapWrapper>
                <SocialBar>
                    <a href={Business.facebook} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon 
                            icon={faFacebookF} 
                            color={theme.palette.primary.light}
                            style={{width: '23px', height: '23px'}}/>
                    </a>
                    <a href={Business.twitter} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon 
                            icon={faTwitter} 
                            color={theme.palette.primary.light}
                            style={{width: '25px', height: '25px'}}/>
                    </a>
                    <a href={Business.instagram} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon 
                            icon={faInstagram} 
                            color={theme.palette.primary.light}
                            style={{width: '25px', height: '25px'}}/>
                    </a>
                    <a href={Business.youtube} target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon 
                            icon={faYoutube} 
                            color={theme.palette.primary.light}
                            style={{width: '25px', height: '22px'}}/>
                    </a>
                </SocialBar>
                <InfoAreaWrapper>
                    <InfoArea>
                        <ContactArea margin="0 100px 0 0">
                            <ContactAreaSection>
                                <FontAwesomeIcon icon={faMapMarkerAlt} color="white" style={{width: '18px', height: '18px'}}/>
                                <ContactAreaLink href="https://goo.gl/maps/ssjtxkMuv692" target="_blank" rel="noopener noreferrer">
                                    <InfoText>201 NW Victoria Dr</InfoText>
                                    <InfoText>Lees Summit, MO 64086</InfoText>
                                    <InfoText>United States</InfoText>
                                </ContactAreaLink>
                            </ContactAreaSection>
                            <ContactAreaSection>
                                <FontAwesomeIcon icon={faPhone} color="white" flip="horizontal"/>
                                <ContactAreaLink href={`tel:+1${Business.phoneNumber}`}>
                                    <InfoText>+1 {Business.phoneNumber}</InfoText>
                                </ContactAreaLink>
                            </ContactAreaSection>
                            <ContactAreaSection>
                                <FontAwesomeIcon icon={faEnvelope} color="white"/>
                                <ContactAreaLink href={`mail:${Business.email}`}>
                                    <InfoText>{Business.email}</InfoText>
                                </ContactAreaLink>
                            </ContactAreaSection>
                        </ContactArea>
                        <HoursGrid>
                            <SectionTitle>Hours</SectionTitle>
                            <MondayDay>Mon</MondayDay>
                            <MondayTime>9:00 AM - 5:00 PM</MondayTime>
                            <TuesdayDay>Tue</TuesdayDay>
                            <TuesdayTime>9:00 AM - 5:00 PM</TuesdayTime>
                            <WednesdayDay>Wed</WednesdayDay>
                            <WednesdayTime>9:00 AM - 5:00 PM</WednesdayTime>
                            <ThursdayDay>Thu</ThursdayDay>
                            <ThursdayTime>9:00 AM - 5:00 PM</ThursdayTime>
                            <FridayDay>Fri</FridayDay>
                            <FridayTime>9:00 AM - 5:00 PM</FridayTime>
                            <SaturdayDay>Sat</SaturdayDay>
                            <SaturdayTime>Closed</SaturdayTime>
                            <SundayDay>Sun</SundayDay>
                            <SundayTime>Closed</SundayTime>
                        </HoursGrid>
                    </InfoArea>
                    <Copyright>Copyright © {new Date().getFullYear()} Strange Music Inc. All Rights Reserved.</Copyright>
                </InfoAreaWrapper>
                {expandedPrivacyPolicy}
            </Wrapper>
        )
    }
}